import React from 'react';
import { useNavigate } from 'react-router-dom';
import sinarmasLogo from '../assets/sinarmasLogo.svg';
import desktopIcon from '../assets/desktopIcon.svg';
import './Homepage.css';

const Homepage = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/');
  };
  
  const handleClick = () => {
    navigate('/desktop');
  };

  return (
    <div className="homepage">
      <div className="banner">
        <img src={sinarmasLogo} alt="Sinarmas Logo" />
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </div>
      <hr className="separator-line" />
      <div className="homepage-container">
        <div className="box" onClick={handleClick}>
          <div className='box-title'>MVD Production</div>
          <img src={desktopIcon} alt="Desktop Icon" className="desktop-icon" />
        </div>
      </div>
    </div>
  );
};

export default Homepage;