import React, { useState, useEffect, useMemo } from "react";
import {
  createStore,
  createDirectLine,
  renderWebChat,
} from "botframework-webchat";
import "./App.css";
import Eva from "./assets/Eva.png";
import axios from "axios";

const WebChatComponent = () => {
  const store = useMemo(() => createStore(), []);

  const styleOptions = useMemo(() => ({
    // paddingRegular: 10,
    // paddingWide: 10 * 2,

    backgroundColor: "#212124",
    primaryFont: "Arial",
    avatarSize: 40,
    botAvatarBackgroundColor: "white",
    botAvatarImage: Eva,
    botAvatarInitials: "EV",
    showAvatarInGroup: true,

    bubbleBackground: "#303030",
    bubbleBorderColor: "#303030",
    bubbleBorderRadius: 5,

    bubbleFromUserBackground: "#28283C",
    bubbleFromUserBorderColor: "#303030",
    bubbleFromUserBorderRadius: 5,
    bubbleFromUserTextColor: "white",
    bubbleImageHeight: "40vh",
    bubbleTextColor: "white",

    sendBoxBorderRadius: 5,
    sendBoxBorderStyle: "solid",
    sendBoxBackground: "#303030",
    sendBoxButtonColor: "white",
    sendBoxPlaceholderColor: "white",
    sendBoxTextColor: "white",
    hideUploadButton: true,
    hideSendBox: false,

    timestampFormat: "absolute",
    scrollToEndButtonFontSize: 8,

    typingAnimationDuration: 86400000,
    typingAnimationHeight: 20,
    typingAnimationWidth: 64,

    adaptiveCardsParserMaxVersion: '1.6'
  }), []);

  const [streamUrl, setStreamUrl] = useState(null);
  const [directLine, setDirectLine] = useState(null);
  const [directLineToken, setDirectLineToken] = useState(null);
  const NIK = sessionStorage.getItem('nik');

  const fetchDirectLineToken = async () => {
    try {
      const response = await axios.post(`https://genai-api.nawatech.co/directline/token?NIK=${NIK}`, {}, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        const { token, conversationId, streamUrl, expires_in } = await response.data;
        setDirectLineToken(token);
        setStreamUrl(streamUrl);
      }
    } catch (error) {
      console.error('Error fetching Direct Line token:', error);
    }
  };

  const refreshDirectLineToken = async () => {
    const NIK = sessionStorage.getItem('nik');

    axios.post(`https://genai-api.nawatech.co/directline/refresh-token?prevToken=${directLineToken}&NIK=${NIK}`, {
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          const { token, expires_in } = response.data;
          setDirectLineToken(token);
        }
      })
      .catch(error => {
        console.error('Error refreshing Direct Line token:', error);
      });
  }

  useEffect(() => {
    if (!streamUrl) {
      fetchDirectLineToken();
    }
  }, [streamUrl]);

  useEffect(() => {
    const initializeChat = async () => {
      if (directLineToken && streamUrl) {
        const directLine = createDirectLine({
          token: directLineToken,
          activityTimeout: 86400000,
          webSocket: new WebSocket(streamUrl),
        });

        directLine.connectionStatus$
          .subscribe(connectionStatus => {
            if (connectionStatus === 4) {
              refreshDirectLineToken();
            }
          });

        setDirectLine(directLine);
      }
    };

    initializeChat();
  }, [directLineToken, streamUrl]);

  useEffect(() => {
    if (directLine) {
      const webChatContainer = document.getElementById('webchat');

      if (webChatContainer) {
        renderWebChat({ directLine, store, styleOptions }, webChatContainer);
      }
    }
  }, [directLine, store, styleOptions]);

  useEffect(() => {
    let refreshTimer;

    if (directLineToken) {

      refreshTimer = setTimeout(() => {
        refreshDirectLineToken()
      }, 15 * 60 * 1000);
    }

    return () => clearTimeout(refreshTimer);
  }, [directLineToken]);

  return <div id="webchat" className="webchat-container" />;
};

export default WebChatComponent;