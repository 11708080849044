import React, { useState, forwardRef, useImperativeHandle } from "react";
import successAlert from "./../assets/successAlert.svg";
import errorAlert from "./../assets/errorAlert.svg";
import cancelIcon from "./../assets/cancelNotification.svg";

const Alert = forwardRef((props, ref) => {
  const [alert, setAlert] = useState(null);

  useImperativeHandle(ref, () => ({
    show: ({ type, title, message, duration = 5000 }) => {
      setAlert({ type, title, message });
      setTimeout(() => {
        setAlert(null);
      }, duration);
    },
    hide: () => {
      setAlert(null);
    },
  }));

  return (
    alert && (
      <div className={`notification ${alert.type}`}>
        {alert.type === "success" && (
          <img src={successAlert} alt="success alert" />
        )}
        {alert.type === "error" && <img src={errorAlert} alt="error alert" />}
        <h4>{alert.title}</h4>
        <p>{alert.message}</p>
        <img
          className="close-icon"
          src={cancelIcon}
          alt="close icon"
          onClick={() => setAlert(null)}
        />
      </div>
    )
  );
});

export default Alert;
