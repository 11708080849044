import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from "./LoginPage/LoginPage"
import Homepage from "./Homepage/Homepage";
import MVDDesktop from './MVDDesktop/MVDDesktop';
import "./App.css";
import PrivateRoutes from './auth/PrivateRoute';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/home" element={<Homepage />} />
          <Route path="/desktop" element={<MVDDesktop />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;