const { Outlet, Navigate } = require("react-router-dom");


const PrivateRoutes = () => {
    const getNikFromSession = () => {
        return sessionStorage.getItem('nik') ;
    }
    const getRoleFromSession = () => {
        const role = JSON.parse(sessionStorage.getItem('role'));
        return role.production;
    }
    const auth = getNikFromSession() && getRoleFromSession();
    return (
        auth ? <Outlet /> : <Navigate to={'/'} />
    )
}

export default PrivateRoutes;