import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./EvaButton.css";
import Eva from "../assets/Eva.png";
import EvaDashboard from "../EvaDashboard/EvaDashboard";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../redux/slices/sidebarSlices";
import Draggable from "react-draggable";

const EvaButton = () => {
  const isSidebarOpen = useSelector((state) => state.sidebar.isSidebarOpen);
  const dispatch = useDispatch();
  const [isMove, setIsMove] = useState(false);

  const onClickMoveable = () => {
    if(isMove){
      setIsMove(false)
    }else{
      setIsMove(true)
    }
  }
  return (
    <>
      <Draggable disabled={!isMove} defaultPosition={{x:0, y:10}}>
        <div className={isMove ? 'moveable' : ''}>
          <button className="eva-btn" onClick={() => dispatch(toggleSidebar())} disabled={isMove}>
            <span className="eva-btn-text">EVA</span>
          </button>
          <img className="eva-btn-image" alt="EVA" src={Eva} onDoubleClick={onClickMoveable} />
        </div>
      </Draggable>
      <div className={`sliding-div ${isSidebarOpen ? "visible" : ""}`}>
        <EvaDashboard />
      </div>
    </>
  );
};

export default EvaButton;
