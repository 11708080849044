import React, { useState, useEffect } from 'react';
import SinarmasImage from "../assets/sinarmas.png"
import { useNavigate } from 'react-router-dom';
import "./LoginPage.css"
import axios from 'axios';

const LoginPage = () => {
  const [roleError, setRoleError] = useState('');
  const [token, setToken] = useState('');
  const [nik, setNik] = useState('');
  const navigate = useNavigate();

  // uncomment once sso is ready

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const queryToken = urlParams.get('token'); 
  //   const queryNik = urlParams.get('nik'); 

  //   if (queryToken && queryNik) {
  //     sessionStorage.setItem('nik', queryNik);
  //     navigate('/home');
  //   }
  // }, [navigate]);

  // const handleLogin = () => {
  //   //change to sinarmas sso url
  //   const ssoUrl = `http://localhost:3001?nik=${nik}`;

  //   window.location.href = ssoUrl;
  // };

  const handleLogin = async () => {
    sessionStorage.clear();
    sessionStorage.setItem('nik', nik);
    const url = `https://genai-api.nawatech.co/api/users/get/${nik}`;
    try {
      const response = await axios.get(url, {
        headers: {
          'accept': 'application/json',
          'X-API-Key': 'e1a38197a2c0fa11176188725b6e206dc824203f',
        },
      });
      if (response.status == 200) {
        if(!response.data.data.role.production){
          setRoleError("Invalid role account")
        }else{
          sessionStorage.setItem("role", JSON.stringify(response.data.data.role));
          navigate('/home')
        }
      }
    } catch (error) {
      if (error.status === 400) {
        setRoleError('Invalid account')
      }else{
        setRoleError('Login Failed')
      }
    }

  };

  return (
    <div className="login-container">
      <img src={SinarmasImage} alt="Sinarmas Logo" className="logo" />
      <div className="disclaimer">
        Please do not put in your real password, this is just for testing purposes.
      </div>
      {roleError ? <p style={{ margin: 0, paddingBottom: "8px", color: "red", textAlign: "left" }}>{roleError}</p> : null}
      <div className="login-form">
        <input
          type="text"
          placeholder="Enter NIK"
          value={nik}
          onChange={(e) => setNik(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Enter Password"
          value={token}
          onChange={(e) => setToken(e.target.value)}
          required
        />
        <button onClick={handleLogin}>Login with SSO</button>
      </div>
    </div>
  );
};

export default LoginPage;